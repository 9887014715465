<template>
  <div class="news-template">
    <div class="header"></div>
    <div class="title">
      <h3>新闻列表</h3>
    </div>
    <el-divider></el-divider>
    <div class="content"  @click="viewnews">
      <el-row
        class="newsListRow"
        :gutter="20"
        v-for="(o, index) in listList"
        :key="index"
        :offset="(index + 1) % 2 === 0 ? 1 : 0"
      >
        <el-col :span="4" class="preImgWrapper">
          <img :src="o.preImg" alt class="preImg" />
        </el-col>
        <el-col :span="15" :offset="1">
          <div class="elTextWrapper">
            <p class="textTitle"  :data-key="index">{{ o.title }}</p>
            <p class="textContent">{{ o.content }}</p>
            <p class="textTime">{{ o.time }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="newsfooter"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      listList:  [
        {
          preImg: "/images/news.jpg",
          title: "中国物品编码中心深圳分中心赴东莞调研进口冻品追溯数据互联互通工作",
          content:
            "1月7日下午，深圳市市场监督管理局指挥协调处与中国物品编码中心深圳分中心食品追溯团队赴东莞市集中监管仓、东莞市市场监督管理局就东莞进口冻品追溯监管情况调研，探讨深莞两地...",
          time: "发表时间: ：2021-01-25 10:58:31",
        },
        {
          preImg: "/images/news1.jpg",
          title: "中国物品编码中心召开中层干部会议传达学习全国市场监管工作会议精神",
          content:
            "2021年2月4日上午，中国物品编码中心（以下简称编码中心）召开年终中层干部会议，编码中心主任张成海、副主任罗秋科、总工程师李建辉、纪委书记岳善...",
          time: "发表时间: 2021-01-16 11:47:29",
        },
        {
          preImg: "/images/news2.jpg",
          title: "中国物品编码中心广西分中心赴广西威利方舟科技有限公司开展UDI调研工作",
          content:
            "为深入了解医疗器械唯一标识（UDI）的应用情况，近日，中国物品编码中心广西分中心赴广西威利方舟科技有限公司开展调研工作，实地了解企业在实施UDI过程中的问题和需求...",
          time: "发表时间: 2020-09-24 11:09:19",
        },
      ],
    };
  },
  methods: {
    viewnews(e) {
       console.log(e.target.dataset);
      let id = e.target.dataset.key;
     if(id){
      switch (id) {
         case '0': 
           window.open(this.$router.resolve({path:"/news"}).href,'_blank')
          break;
         case '1': 
         window.open(this.$router.resolve({path:"/news1"}).href,'_blank')
          break;
           case '2': 
           window.open(this.$router.resolve({path:"/news2"}).href,'_blank')
          break;
        default:
          break;
      }
      
    }
    },
  },
};
</script>
<style lang="scss" scoped>
.news-template {
  width: 100%;
  font-family: Hiragino Sans GB, Microsoft Yahei, \\5fae\8f6f\96c5\9ed1, SimSun,
    \\5b8b\4f53, Arial;
  .header {
    height: 6em;
    background: #04c9a0;
  }
  h3 {
    font-size: 1.7em;
    margin: 0.7em 0;
    text-align: center;
  }
  h5 {
    margin: 0.5em 0;
    color: #909399;
  }
  .content {
    width: 65%;
    margin: 0 17%;
    .newsListRow {
      display: flex;
      padding: 20px;
      border-bottom: 1px solid #e6e6e6;
       .preImgWrapper{
      display: flex;
      .preImg{
        width: 100%;
      }
    }
      .elTextWrapper {
        display: inline-block;
        vertical-align: middle;
        padding: 10px 0;
        .textTitle {
          cursor: pointer;
          color: #000000;
          font-size: 20px;
          margin-top: 20px;
        }
        .textContent {
          color: #666666;
          font-size: 15px;
          margin-top: 20px;
          line-height: 1.5;
        }
        .textTime {
          color: #999999;
          font-size: 13px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>